import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

function Footer() {
  return (
    <footer>
      <div className="content">
        <div className="top">
          <div className="logo-details">
            <i className="fab fa-slack"></i>
            <span className="logo_name">OneTap-Anime</span>
          </div>
          <div className="media-icons">
            <div className="social">
              <Link to="/">
                <i className="fab fa-telegram"></i>
              </Link>
              <Link to="/">
                <i className="fab fa-youtube"></i>{" "}
              </Link>
              <Link to="/">
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="link-boxes">
          <ul className="box">
            <li className="link_name">OneTap</li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/subbed-anime">Subbed Anime</Link>
            </li>
            <li>
              <Link to="/dubbed-anime">Dubbed Anime</Link>
            </li>
            <li>
              <Link to="/movies">Movies</Link>
            </li>
            <li>
              <Link to="/ova">OVA</Link>
            </li>
          </ul>

          <ul className="box">
            <li className="link_name">Categories 1</li>
            <li>
              <Link to="/most-favorite">Most Favorite</Link>
            </li>
            <li>
              <Link to="/most-popular">Most Popular</Link>
            </li>
            <li>
              <Link to="/recently-added">Recently Added</Link>
            </li>
            <li>
              <Link to="/recently-updated">Recently Updated</Link>
            </li>
          </ul>

          <ul className="box">
            <li className="link_name">Categories 2</li>
            <li>
              <Link to="/completed">Completed</Link>
            </li>

            <li>
              <Link to="/ona">ONA</Link>
            </li>

            <li>
              <Link to="/special">Special</Link>
            </li>

            <li>
              <Link to="/tv">TV</Link>
            </li>
          </ul>

          <ul className="box">
            <li className="link_name">Who We Are</li>
            <li>
              <Link to="">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>

          {/* <ul className="box">
            <li className="link_name">Genres</li>
            <li>
              <Link to="/genre/Action">Action</Link>
            </li>
            <li>
              <Link to="/genre/Adventure">Adventure</Link>
            </li>
            <li>
              <Link to="/genre/Fantasy">Fantasy</Link>
            </li>
            <li>
              <Link to="/genre/Shounen">Shounen</Link>
            </li>
            <li>
              <Link to="/genre/Super Power">Super Power</Link>
            </li>
          </ul> */}

          <ul className="box input-box">
            <li className="link_name">Coming Soon...</li>
            {/* <li>
              <input type="text" placeholder="Enter your email" />
            </li>
            <li>
              <input type="button" value="Subscribe" />
            </li> */}
            <li>
              <button className="glowing-btn">
                <span className="glowing-txt">
                  L<span className="faulty-letter">O</span>GIN!
                </span>
              </button>
            </li>
          </ul>

          <ul className="box">
            <li className="link_name">Designed By</li>
            <li>
              <Link to="https://mindmarq.in/" target="_blank">
                Mind Marq Technology
              </Link>
            </li>
            <li>
              <div className="social">
                <Link to="https://api.whatsapp.com/send/?phone=918451046210&text&type=phone_number&app_absent=0" target="_blank">
                  <i className="fab fa-whatsapp"></i>
                </Link>
                <Link to="https://www.linkedin.com/company/mind-marq-technologies" target="_blank">
                  <i className="fab fa-linkedin"></i>
                </Link>
                <Link to="https://www.instagram.com/mindmarqtec/?igsh=cXE3MTRld3B1bnBh" target="_blank">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-details">
        <div className="bottom_text" style={{ textAlign: "center", justifyContent: "center" }}>
          <span className="copyright_text">
            &#169; 2024, <Link to="/">OneTap-Anime,</Link> <br /> All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
